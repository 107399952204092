import React, { useContext, useEffect, useState } from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';
import Countdown from 'react-countdown';

import * as style from 'styles/components/match/splash.module.scss';
import { LangContext } from 'context/LangContext';
import { RoutesContext } from 'context/RoutesContext';
import useMatchSplash from 'data/queries/useMatchSplash';
import CustomLink from 'components/utils/CustomLink';
import FormattedMessage from 'components/utils/FormattedMessage';

const Splash = ({ matches }) => {
    console.log(matches);
    const match = matches[0];
    const { lang } = useContext(LangContext);
    const { routes } = useContext(RoutesContext) || { routes: [] };

    const matchSplashData = useMatchSplash();
    const [hrefs, setHrefs] = useState([]);

    useEffect(() => {
        const tmpHrefs = [];
        if (routes && routes.length !== 0) {
            matches.forEach((singleMatch) => {
                tmpHrefs.push(
                    `/${
                        routes.find((r) => r.node.name === 'matchList' && r.node.lang === lang.hreflang)
                            .node.path
                    }/${singleMatch.slug.current}`,
                );
            });
            setHrefs(tmpHrefs);
        }
    }, [lang, routes, matches]);

    const buildMatchFormattedDate = (startEventDate) => {
        const date = new Date(startEventDate);
        const formattedDate = date.toLocaleDateString(lang.iso, {
            weekday: 'short',
            day: 'numeric',
            month: 'long',
        });
        return formattedDate;
    }

    const buildMatchTime = (startEventDate) => {
        const date = new Date(startEventDate);
        return (
            <>
                {date.getHours()<10?'0':''}{date.getHours()}:{date.getMinutes()<10?'0':''}{date.getMinutes()}
            </>
        );
    }

    const buildMatchDate = (startEventDate) => {
        const date = new Date(startEventDate);
        return date;
    }

    const backgroundHref = matchSplashData?.matchVisual?.asset.url || '';
    const backgroundHrefWomen = matchSplashData?.matchVisualWomen?.asset.url || '';

    const renderSponsor = (url, image, title) => (
        <div className={style.splash__sponsorMultiple}>
            <p><FormattedMessage id="match.partnerSponsoredBy" /></p>
            <a href={url} target="_blank" rel="noreferrer">
                <ImageGatsby
                    {...image}
                    alt={title}
                    title={title}
                    width={60}
                />
            </a>
        </div>
    );

    const rendererSingle = ({ completed, formatted }) => {
        if (completed) {
            return <span className={style.splash__count__completed}><FormattedMessage id="match.matchStarted" /></span>;
        }
        return (
            <>
                <p className="text-sm-mobile text-lg uppercase"><FormattedMessage id="match.matchStart" /></p>
                <div className={style.splash__count__wrapper}>
                    <div className={style.splash__count__item}>
                        <span className={style.splash__count__number}>{formatted.hours}</span>
                        <span className={`${style.splash__count__info} text-md-mobile text-darker`}>
                            HH
                        </span>
                    </div>
                    <span className={style.splash__count__colon}>:</span>
                    <div className={style.splash__count__item}>
                        <span className={style.splash__count__number}>{formatted.minutes}</span>
                        <span className={`${style.splash__count__info} text-md-mobile text-darker`}>
                            MM
                        </span>
                    </div>
                    <span className={style.splash__count__colon}>:</span>
                    <div className={style.splash__count__item}>
                        <span className={style.splash__count__number}>{formatted.seconds}</span>
                        <span className={`${style.splash__count__info} text-md-mobile text-darker`}>
                            SS
                        </span>
                    </div>
                </div>
            </>
        );
    };

    const rendererMultiple = ({ completed, formatted }) => {
        if (completed) {
            return <span className={style.splash__count__completed}><FormattedMessage id="match.matchStarted" /></span>;
        }
        return (
            <>
                <p className="text-sm-mobile text-lg uppercase"><FormattedMessage id="match.matchStart" /></p>
                <div className={style.splash__countMultiple__wrapper}>
                    <div className={style.splash__countMultiple__item}>
                        <span className={style.splash__countMultiple__number}>{formatted.hours}</span>
                        <span className={`${style.splash__countMultiple__info} text-md-mobile text-darker`}>
                            HH
                        </span>
                    </div>
                    <span className={style.splash__countMultiple__colon}>:</span>
                    <div className={style.splash__countMultiple__item}>
                        <span className={style.splash__countMultiple__number}>{formatted.minutes}</span>
                        <span className={`${style.splash__countMultiple__info} text-md-mobile text-darker`}>
                            MM
                        </span>
                    </div>
                    <span className={style.splash__countMultiple__colon}>:</span>
                    <div className={style.splash__countMultiple__item}>
                        <span className={style.splash__countMultiple__number}>{formatted.seconds}</span>
                        <span className={`${style.splash__countMultiple__info} text-md-mobile text-darker`}>
                            SS
                        </span>
                    </div>
                </div>
            </>
        );
    };

    const renderBackground = ( background ) => (
        <div
        className={style.splash__singleBackground}
        style={{
            background:
                `${
                    '  url(\''
                }${background}') center center / cover`,
            backgroundBlendMode: 'screen, normal, normal, normal, normal, normal, normal',
        }}
        />
    );

    return (
        <div className={style.splash}>
            <div
                className={style.splash__backgrounds}
                style={{
                    background:
                    `${
                        'url(\'../../images/splash/splash_overlay.png\') 0 0 / cover,' +
                        '  linear-gradient(180deg, rgba(0, 0, 0, 0) 54.86%, rgba(0, 0, 0, 0.2) 100%),' +
                        '  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),' +
                        '  linear-gradient(180deg, rgba(0, 0, 0, 0.1) 5.31%, rgba(0, 0, 0, 0) 21.25%),' +
                        '  linear-gradient(119.2deg, rgba(21, 71, 52, 0) 1.51%, rgba(21, 71, 52, 0.6) 46%),'
                    }`,
                }}
            >
                <div className={style.splash__backgroundCover} />

                {matches[0].team._id === 'team-2' || matches[0].teamExternal._id === 'team-2' ? renderBackground(backgroundHref) : renderBackground(backgroundHrefWomen)}
                {matches.length > 1 && (
                    matches[1].team._id === 'team-2' || matches[1].teamExternal._id === 'team-2' ? renderBackground(backgroundHref) : renderBackground(backgroundHrefWomen)
                )}
            </div>

            <div className={style.splash__anim} />

            {matches.length > 1 ? (
                <div className={style.splash__contentMultiple}>
                    <div className={`${style.splash__match} ${matches[0].team._id === 'team-2' || matches[0].teamExternal._id === 'team-2' ? 'teamMen' : 'teamWomen'}`}>
                        <p className={style.splash__competitionInfo}>
                            {matches[0].competition.title}
                        </p>
                        <div className={style.splash__dateMultiple}>
                            <p className={style.splash__dateMultiple__top}>{buildMatchFormattedDate(matches[0].startEventDate)}</p>
                            <p className="title-xl text-semibold">
                                {buildMatchTime(matches[0].startEventDate)}
                            </p>
                        </div>

                        <div className={style.splash__clubsMultiple}>
                            <div className={style.splash__clubsMultiple__logo}>
                                {matches[0].local?.image?.asset && (
                                    <ImageGatsby
                                        {...matches[0].local.image}
                                        alt={matches[0].local.title}
                                        title={matches[0].local.title}
                                        width={60}
                                    />
                                )}
                            </div>
                            <div className={style.splash__clubsMultiple__names}>
                                <p className={style.splash__clubsMultiple__name}>{matches[0].local.title}</p>
                                <p className={`${style.splash__clubsMultiple__vs} text-darker uppercase`}>–</p>
                                <p className={style.splash__clubsMultiple__name}>{matches[0].external.title}</p>
                            </div>
                            <div className={style.splash__clubsMultiple__logo}>
                                {matches[0].external?.image?.asset && (
                                    <ImageGatsby
                                        {...matches[0].external.image}
                                        alt={matches[0].external.title}
                                        title={matches[0].external.title}
                                        width={60}
                                    />
                                )}
                            </div>
                        </div>

                        <div className={style.splash__countMultiple}>
                            <Countdown date={buildMatchDate(matches[0].startEventDate)} renderer={rendererMultiple} zeroPadTime={2} />
                        </div>

                        <div className={style.splash__join}>
                            <CustomLink to={hrefs[0]} className="cta-white">
                                <FormattedMessage id="homepage.joinLive" />
                            </CustomLink>
                        </div>

                        {!matches[0].primarySponsor && matchSplashData.matchSponsor && (
                            renderSponsor(
                                matchSplashData.matchSponsor.url,
                                matchSplashData.matchSponsor.logoWhite,
                                matchSplashData.matchSponsor.title,
                            )
                        )}

                        {matches[0].primarySponsor && (
                            renderSponsor(
                                matches[0].primarySponsor.url,
                                matches[0].primarySponsor.logoWhite,
                                matches[0].primarySponsor.title,
                            )
                        )}

                    </div>
                    <div className={`${style.splash__match} ${matches[1].team._id === 'team-2' || matches[1].external._id === 'team-2' ? 'teamMen' : 'teamWomen'}`}>
                        <p className={style.splash__competitionInfo}>
                            {matches[1].competition.title}
                        </p>
                        <div className={style.splash__dateMultiple}>
                            <p className={style.splash__dateMultiple__top}>{buildMatchFormattedDate(matches[1].startEventDate)}</p>
                            <p className="title-xl text-semibold">
                                {buildMatchTime(matches[1].startEventDate)}
                            </p>
                        </div>

                        <div className={style.splash__clubsMultiple}>
                            <div className={style.splash__clubsMultiple__logo}>
                                {matches[1].local?.image?.asset && (
                                    <ImageGatsby
                                        {...matches[1].local.image}
                                        alt={matches[1].local.title}
                                        title={matches[1].local.title}
                                        width={60}
                                    />
                                )}
                            </div>
                            <div className={style.splash__clubsMultiple__names}>
                                <p className={style.splash__clubsMultiple__name}>{matches[1].local.title}</p>
                                <p className={`${style.splash__clubsMultiple__vs} text-darker uppercase`}>–</p>
                                <p className={style.splash__clubsMultiple__name}>{matches[1].external.title}</p>
                            </div>
                            <div className={style.splash__clubsMultiple__logo}>
                                {matches[1].external?.image?.asset && (
                                    <ImageGatsby
                                        {...matches[1].external.image}
                                        alt={matches[1].external.title}
                                        title={matches[1].external.title}
                                        width={60}
                                    />
                                )}
                            </div>
                        </div>

                        <div className={style.splash__countMultiple}>
                            <Countdown date={buildMatchDate(matches[1].startEventDate)} renderer={rendererMultiple} zeroPadTime={2} />
                        </div>

                        <div className={style.splash__join}>
                            <CustomLink to={hrefs[1]} className="cta-white">
                                <FormattedMessage id="homepage.joinLive" />
                            </CustomLink>
                        </div>

                        {!matches[1].primarySponsor && matchSplashData.matchSponsor && (
                            renderSponsor(
                                matchSplashData.matchSponsor.url,
                                matchSplashData.matchSponsor.logoWhite,
                                matchSplashData.matchSponsor.title,
                            )
                        )}

                        {matches[1].primarySponsor && (
                            renderSponsor(
                                matches[1].primarySponsor.url,
                                matches[1].primarySponsor.logoWhite,
                                matches[1].primarySponsor.title,
                            )
                        )}

                    </div>
                </div>
            ) : (
                <div className={style.splash__content}>
                    <div className={style.splash__date}>
                        <p className={style.splash__date__top}>{buildMatchFormattedDate(matches[0].startEventDate)}</p>
                        <p className="title-xl text-semibold">
                            {buildMatchTime(matches[0].startEventDate)}
                        </p>
                    </div>

                    <div className={style.splash__clubs}>
                        <div className={style.splash__clubs__logo}>
                            {matches[0].local?.image?.asset && (
                                <ImageGatsby
                                    {...matches[0].local.image}
                                    alt={matches[0].local.title}
                                    title={matches[0].local.title}
                                    width={60}
                                />
                            )}
                        </div>
                        <div className={style.splash__clubs__names}>
                            <p className={style.splash__clubs__name}>{matches[0].local.title}</p>
                            <p className={`${style.splash__clubs__vs} text-darker uppercase`}>–</p>
                            <p className={style.splash__clubs__name}>{matches[0].external.title}</p>
                        </div>
                        <div className={style.splash__clubs__logo}>
                            {matches[0].external?.image?.asset && (
                                <ImageGatsby
                                    {...matches[0].external.image}
                                    alt={matches[0].external.title}
                                    title={matches[0].external.title}
                                    width={60}
                                />
                            )}
                        </div>
                    </div>

                    <div className={style.splash__count}>
                        <Countdown date={buildMatchDate(matches[0].startEventDate)} renderer={rendererSingle} zeroPadTime={2} />
                    </div>

                    <div className={style.splash__join}>
                        <CustomLink to={hrefs[0]} className="cta-white">
                            <FormattedMessage id="homepage.joinLive" />
                        </CustomLink>
                    </div>

                    {matchSplashData.matchSponsor && (
                        renderSponsor(
                            matchSplashData.matchSponsor.url,
                            matchSplashData.matchSponsor.logoWhite,
                            matchSplashData.matchSponsor.title,
                        )
                    )}

                    {!matchSplashData.matchSponsor && match.primarySponsor && (
                        renderSponsor(
                            match.primarySponsor.url,
                            match.primarySponsor.logoWhite,
                            match.primarySponsor.title,
                        )
                    )}
                </div>
            )}
        </div>
    );
};

export default Splash;

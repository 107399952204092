import React, { useState, useRef, useContext } from 'react';
import Slider from 'react-slick';

import { RoutesContext } from 'context/RoutesContext';
import CustomLink from 'components/utils/CustomLink';
import EmbedVideoPlayer from 'components/utils/EmbedVideoPlayer';
import Modal from 'components/utils/Modal';
// import Loader from 'components/utils/Loader';
import useNewsBanner from 'data/queries/useNewsBanner';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import { LangContext } from 'context/LangContext';
import * as style from 'styles/components/homepage/newsBannerUpdated.module.scss';

import defaultImage from '../../images/default-image.jpg';

const SlickArrow = (props)  => {
    const { className, onClick } = props;
    return(
        <button type="button" onClick={onClick} className={`arrow ${className}`} >
            <i className="ico-chevron" aria-hidden="true" />
            <span className="visually-hidden">Flubar</span>
        </button>
      )
}

const NewsBanner = ({ isHomepage }) => {
    const { lang } = useContext(LangContext);
    const { routes } = useContext(RoutesContext) || { routes: [] };

    const news = useNewsBanner().filter((items) => items.language.hreflang === lang.hreflang);

    let newsListRoute = '';
    if (routes && routes.length !== 0) {
        newsListRoute = routes.find(
            (r) => r.node.name === 'newsList' && r.node.lang === lang.hreflang,
        ).node.path;
    }

    const sliderRef = useRef();

    const [activeSlide, setActiveSlide] = useState(0);
    const [nextActiveSlide, setNextActiveSlide] = useState(1);
    const [prevActiveSlide, setPrevActiveSlide] = useState(news.length - 1);

    const [changing, setChanging] = useState(false);
    const [prevChanging, setPrevChanging] = useState(false);

    const [videoPlaying, setVideoPlaying] = useState(false);

    // const [animate, setAnimate] = useState(false);

    const sliderBeforeChange = (oldIndex, newIndex) => {
        if(oldIndex === 0 && newIndex === news.length - 1) {
            setPrevChanging(true);
        } else if(oldIndex === news.length - 1 && newIndex === 0) {
            setChanging(true);
        } else if( oldIndex < newIndex ) {
            setChanging(true);
        } else {
            setPrevChanging(true);
        }
    };

    const sliderAfterChange = (current) => {
        setChanging(false);
        setPrevChanging(false);
        let prevNumber = 0;
        if( current === 0 ) {
            prevNumber = news.length - 2;
        } else if (current === 1) {
            prevNumber = news.length - 1;
        } else {
            prevNumber = current - 2;
        }
        setActiveSlide(current === 0 ? news.length - 1 : current - 1);
        setNextActiveSlide(current);
        setPrevActiveSlide(prevNumber);
    };

    const sliderSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 400,
        autoplaySpeed: 8000,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
        swipe: true,
        draggable: false,
        autoplay: false,
        nextArrow: <SlickArrow to="next" />,
        prevArrow: <SlickArrow to="prev" />,
        beforeChange: (before, after) => sliderBeforeChange(before, after),
        afterChange: (current) => sliderAfterChange(current),
        onInit: () =>
            setTimeout(() => {
                // setAnimate(true);
            }, 600),
    };

    const buildNewsVisual = (newsItem, className = '') => {
        const defaultVisual = {
            asset: {
                url: defaultImage,
            },
        }
        const visual = newsItem.videoPreviewImage || (newsItem.image && newsItem.image.defaultImage && newsItem.image.defaultImage.image) || defaultVisual;

        const visualPosition = {
            objectPosition: visual.hotspot
                ? `${visual.hotspot.x * 100}% ${visual.hotspot.y * 100}%`
                : '50% 50%',
        };

        return (
            <img
                className={className}
                src={`${visual.asset.url}?w=2000`}
                alt={newsItem.title}
                style={visualPosition}
            />
        );
    };

    return (
        <div className={`${style.slider} ${isHomepage ? 'homepage' : ''}`}>
            {news && news.length && (
                <>
                    <div className={style.slider__main}>
                        {buildNewsVisual(
                            news[prevActiveSlide],
                            `${style.slider__prevVisual} ${prevChanging ? 'changing' : ''}`,
                        )}

                        {buildNewsVisual(news[activeSlide], style.slider__mainVisual)}

                        {buildNewsVisual(
                            news[nextActiveSlide],
                            `${style.slider__nextVisual} ${changing ? 'changing' : ''}`,
                        )}

                        <div className={style.slider__currentVisualMobile}>
                            {buildNewsVisual(news[activeSlide])}
                            <span className={style.thumbnail__number}>O{activeSlide + 1}</span>
                        </div>

                        <div className={style.slider__mainContent}>
                            <span className={style.thumbnail__number}>0{activeSlide + 1}</span>

                            {news[activeSlide].video &&
                                <>
                                    <span className={style.thumbnail__cat} onClick={() => setVideoPlaying(true)} onKeyDown={() => setVideoPlaying(true)} role="presentation">
                                            {news[activeSlide].category.title[lang.hreflang]}
                                    </span>
                                    <h1
                                        className={`title-xl title-sm-mobile text-bold ${style.slider__mainTitle}`}
                                    >
                                            {news[activeSlide].title}
                                    </h1>
                                    <button type="button" className="cta-white-sec" onClick={() => setVideoPlaying(true)} aria-label={getFormattedMessage('global.watchClip', lang)}>
                                        <FormattedMessage id="global.watchClip" />
                                    </button>
                                </>
                            }

                            {!news[activeSlide].video &&
                                <>
                                    <span className={style.thumbnail__cat}>
                                        <CustomLink
                                            to={`${newsListRoute}?cat=${news[activeSlide].category.ref}`}
                                        >
                                            {news[activeSlide].category.title[lang.hreflang]}
                                        </CustomLink>
                                    </span>

                                    <CustomLink to={`/${newsListRoute}/${news[activeSlide].slug.current}`}>
                                    <h1
                                        className={`title-xl title-sm-mobile text-bold ${style.slider__mainTitle}`}
                                    >
                                        {news[activeSlide].title}
                                    </h1>
                                    </CustomLink>
                                    <CustomLink to={`/${newsListRoute}/${news[activeSlide].slug.current}`} className="cta-white-sec">
                                        <FormattedMessage id="global.readNews" />
                                    </CustomLink>
                                </>
                            }
                        </div>
                        {news[activeSlide].video &&
                            <div onClick={() => setVideoPlaying(true)} onKeyDown={() => setVideoPlaying(true)} role="presentation">
                                <div className={style.slider__background} />
                            </div>
                        }
                        {!news[activeSlide].video &&
                            <CustomLink to={`/${newsListRoute}/${news[activeSlide].slug.current}`}>
                                <div className={style.slider__background} />
                            </CustomLink>
                        }
                    </div>

                    <div className={style.thumbnail__container}>
                        <Slider {...sliderSettings} ref={sliderRef}>
                            {news.map((item, index) => (
                                <div key={item.id} className={style.thumbnail}>
                                    {buildNewsVisual(item)}

                                    <div className={style.thumbnail__content}>
                                        <p>
                                            <span className={style.thumbnail__number}>
                                                0{index + 1}
                                            </span>
                                            <span
                                                className={`${style.thumbnail__cat} hidden-mobile`}
                                            >
                                                <CustomLink to={`news?cat=${item.category.ref}`}>
                                                    {item.category.title[lang.hreflang]}
                                                </CustomLink>
                                            </span>
                                        </p>

                                        <p className={style.thumbnail__title}>
                                            <CustomLink
                                                to={`/${newsListRoute}/${item.slug.current}`}
                                            >
                                                {item.title}
                                            </CustomLink>
                                        </p>
                                    </div>

                                    <CustomLink to={`/${newsListRoute}/${item.slug.current}`}>
                                        <div className={style.thumbnail__background} />
                                    </CustomLink>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </>
            )}

            {videoPlaying && (
                <Modal
                    className={style.slider__videoModal}
                    show={videoPlaying}
                    onClose={() => setVideoPlaying(false)}
                >
                <EmbedVideoPlayer
                    url={`${news[activeSlide].video.teaserVideo.url}?auto_play=true`}
                    title={news[activeSlide].title}
                />
                </Modal>
            )}
        </div>
    );
};

export default NewsBanner;

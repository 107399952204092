// extracted by mini-css-extract-plugin
export var progress__bar = "newsBannerUpdated-module--progress__bar--A2Lvl";
export var progress__container = "newsBannerUpdated-module--progress__container--YxVIP";
export var slider = "newsBannerUpdated-module--slider--De2ab";
export var slider__background = "newsBannerUpdated-module--slider__background--jlxaq";
export var slider__currentVisualMobile = "newsBannerUpdated-module--slider__currentVisualMobile--rjsg5";
export var slider__main = "newsBannerUpdated-module--slider__main--MLdF1";
export var slider__mainContent = "newsBannerUpdated-module--slider__mainContent--BqC-+";
export var slider__mainTitle = "newsBannerUpdated-module--slider__mainTitle--qxeu5";
export var slider__mainVisual = "newsBannerUpdated-module--slider__mainVisual--O3YH3";
export var slider__nextVisual = "newsBannerUpdated-module--slider__nextVisual--aEr4p";
export var slider__prevVisual = "newsBannerUpdated-module--slider__prevVisual--Hse2c";
export var slider__videoModal = "newsBannerUpdated-module--slider__videoModal--wGKor";
export var thumbnail = "newsBannerUpdated-module--thumbnail--jwEOq";
export var thumbnail__background = "newsBannerUpdated-module--thumbnail__background--gUW8L";
export var thumbnail__cat = "newsBannerUpdated-module--thumbnail__cat--QQc3G";
export var thumbnail__container = "newsBannerUpdated-module--thumbnail__container--Br2Lg";
export var thumbnail__content = "newsBannerUpdated-module--thumbnail__content--2QfsX";
export var thumbnail__number = "newsBannerUpdated-module--thumbnail__number--wJf1l";
export var thumbnail__title = "newsBannerUpdated-module--thumbnail__title--tVCKE";